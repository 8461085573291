export default [
  {
    path: "/smart-report",
    component: () => import("@/views/SmartReport"),
    children: [
      {
        name: "smart.report",
        path: "/",
        component: () => import("@/components/pages/reports/smart-report/SmartReport")
      }
    ]
  }
];