import store from "../store";

export default (config) => {
  const path = config.url.split("/").pop();
  const { user } = store.state.login;
  if (path !== "sign_in") {
    config.headers.Authorization = user.attributes.access_token;
  }
  return config;
};
