export default {
  SET_QUESTIONS(state, payload) {
    state.questions = payload;
  },
  SET_PAGINATION(state, payload) {
    state.pagination = payload;
  },
  DELETE_QUESTION(state, payload) {
    const index = state.questions.findIndex(e => e.id === payload.id);
    if (index !== -1) {
      state.questions.splice(index, 1);
    }
  },
  SET_QUESTION_REPLIES(state, payload) {
    state.questionReplies = payload;
  },
  SET_QUESTION_LIST(state, payload) {
    state.lists = payload;
  },
  SET_GROUPS(state, payload) {
    state.groups = payload;
  }
};