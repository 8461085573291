import Vue from "vue";
import VueRouter from "vue-router";
/* Rotas */
import Login from "./login";
import Home from "./home";
import Martket from "./market";
import Solutions from "./solutions";
import Questions from "./questions";
import AwnserReport from "./awnser-report";
import Users from "./users";
import SmartReport from "./smart-report";
import SolutionsReport from "./solutions-report";
import Form from "./forms";
Vue.use(VueRouter);

const routes = [
  ...Login,
  ...Home,
  ...Martket,
  ...Solutions,
  ...Questions,
  ...AwnserReport,
  ...Users,
  ...SmartReport,
  ...SolutionsReport,
  ...Form
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;