import api from "@/services/api";
export default {
  async actionGetReport({ commit }, payload) {
    const results = await api.get("v1/reports/provider_results", { params: payload });
    commit("SET_REPORTS", results.data);
  },
  async actionGetProviders({ commit }, payload) {
    const providers = await api.get("v1/forms/" + payload.form + "/get_providers", { params: { user_id: payload.user_id } });
    commit("SET_PROVIDERS", providers.data);
  }
};