import api from "@/services/api";
import form from "../forms";
export default {
  async actionSaveQuestion({ _ }, payload) {
    const question = await api.post(`/v1/forms/${form.state.form.id}/questions`, payload);
    if (question.response) {
      Object.keys(question.response.data.errors).forEach(key => {
        this._vm.$toast.error(key + " " + question.response.data.errors[key][0]);
      });
      return false;
    } else {
      this._vm.$toast.success("Pergunta cadastrada com sucesso !");
      return true;
    }
  },
  async actionSaveEditQuestion({ _ }, payload) {
    const question = await api.put(`/v1/forms/${form.state.form.id}/questions/` + payload.id, payload.question);
    if (question.response) {
      Object.keys(question.response.data.errors).forEach(key => {
        this._vm.$toast.error(key + " " + question.response.data.errors[key][0]);
      });
      return false;
    } else {
      this._vm.$toast.success("Pergunta editada com sucesso !");
      return true;
    }
  },
  async actionGetQuestions({ commit }, payload) {
    const questions = await api.get(`/v1/forms/${form.state.form.id}/questions/list`, { params: payload });
    commit("SET_QUESTIONS", questions.data.data);
    commit("SET_PAGINATION", questions.data.meta);
  },
  async actionGetQuestionList({ commit }) {
    const list = await api.get("/v1/get_lists");
    commit("SET_QUESTION_LIST", list.data);
  },
  async actionGetQuestionlistItems({ _ }, payload) {
    const listItems = await api.get("v1/get_itens_list", { params: payload });
    return listItems.data;
  },
  async actionGetQuestionReplies({ commit }, payload) {
    const questions = await api.get(`/v1/forms/${form.state.form.id}/questions/` + payload + "/replies");
    commit("SET_QUESTION_REPLIES", questions.data);
  },
  async actionDeleteMarket({ commit }, payload) {
    const question = await api.delete(`/v1/forms/${form.state.form.id}/questions/` + payload.id);
    if (question.response) {
      Object.keys(question.response.data.errors).forEach(key => {
        this._vm.$toast.error(key + " " + question.response.data.errors[key][0]);
      });
      return false;
    } else {
      this._vm.$toast.success("Pergunta excluída com sucesso !");
      commit("DELETE_QUESTION", payload);
      return true;
    }
  },
  async actionGetGroups({ commit }, payload) {
    const groups = await api.get("/v1/get_lists/groups", { params: payload });
    commit("SET_GROUPS", groups.data);
  }
};