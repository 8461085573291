export default {
  SET_MARKETS(state, payload) {
    state.markets = payload;
  },
  UPDATE_MARKET(state, payload) {
    const newMarkets = [];
    state.markets.forEach(item => {
      if (item.id === payload.id) {
        item = payload;
      }
      newMarkets.push(item);
    });
    state.markets = newMarkets;
  },
  SET_NEW_MARKET(state, payload) {
    state.markets.unshift(payload);
  },
  DELETE_MARKET(state, payload) {
    const index = state.markets.findIndex(e => e.id === payload.id);
    state.markets.splice(index, 1);
  },
  SET_PAGINATION(state, payload) {
    state.pagination = payload;
  }
};