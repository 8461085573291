import Vue from "vue";
import Vuex from "vuex";
/* storages */
import login from "./login";
import loader from "./loader";
import market from "./market";
import solutions from "./solutions";
import question from "./question";
import forms from "./forms";
import awnserReport from "./awnser-report";
import smartReport from "./smart-report";
import solutionsReport from "./solutions-report";
import users from "./users";
Vue.use(Vuex);
export default new Vuex.Store({
  modules: {
    login,
    loader,
    market,
    solutions,
    question,
    forms,
    awnserReport,
    smartReport,
    solutionsReport,
    users
  }
});