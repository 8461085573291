export default [
  {
    path: "/users",
    component: () => import("@/views/UsersView"),
    children: [
      {
        name: "users.list",
        path: "/",
        component: () => import("@/components/pages/users/UsersList.vue"),
        meta: {
          title: "Cadastro de Consultores"
        }
      }
    ]
  }
];