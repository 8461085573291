import api from "@/services/api";
export default {
  async actionGetMarkets({ commit }, payload) {
    const market = await api.get("/v1/markets", { params: payload });
    commit("SET_MARKETS", market.data.data);
    commit("SET_PAGINATION", market.data.meta);
  },
  async actionEditMarket({ commit }, payload) {
    const marketEdit = await api.put("/v1/markets/" + payload.id, payload.market);
    if (marketEdit.response) {
      Object.keys(marketEdit.response.data.errors).forEach(key => {
        this._vm.$toast.error(key + " " + marketEdit.response.data.errors[key][0]);
      });
      return false;
    } else {
      this._vm.$toast.success("Canal editado com sucesso !");
      commit("UPDATE_MARKET", marketEdit.data);
      return true;
    }
  },
  async actionSaveMarket({ commit }, payload) {
    const createdMarket = await api.post("/v1/markets", payload);
    if (createdMarket.response) {
      Object.keys(createdMarket.response.data.errors).forEach(key => {
        this._vm.$toast.error(key + " " + createdMarket.response.data.errors[key][0]);
      });
      return false;
    } else {
      this._vm.$toast.success("Canal cadastrado com sucesso !");
      commit("SET_NEW_MARKET", createdMarket.data);
      return true;
    }
  },
  async actionDeleteMarket({ commit }, payload) {
    const deletedMarket = await api.delete("/v1/markets/" + payload.id);
    if (deletedMarket.response) {
      Object.keys(deletedMarket.response.data.errors).forEach(key => {
        this._vm.$toast.error(key + " " + deletedMarket.response.data.errors[key][0]);
      });
      return false;
    } else {
      this._vm.$toast.success("Canal excluído com sucesso !");
      commit("DELETE_MARKET", payload);
      return true;
    }
  }
};