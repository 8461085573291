export default [
  {
    path: "/solutions",
    component: () => import("@/views/SolutionsView"),
    children: [
      {
        name: "solutions.list",
        path: "/",
        component: () => import("@/components/pages/solutions/SolutionsList.vue"),
        meta: {
          title: "Cadastro de Iniciativas"
        }
      }
    ]
  }
];