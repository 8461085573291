<template>
  <header class="col-12 d-flex justify-content-between py-10">
    <div class="col-4"></div>
    <div class="col-4"  @click="downwards = !downwards">
      <img
        src="@/assets/logo_sebrae.jpg"
        alt="Sebrae"
        height="60"
        class="logo"
      />
      <i
        v-if="downwards"
        class="mdi mdi-chevron-down"
      ></i>
      <i
        v-else
        class="mdi mdi-chevron-up"
      ></i>
      <div class="box-menu" :class="!downwards ? 'active' : ''">
        <i class="mdi mdi-triangle-small-up arrow"></i>
        <div class="menu-content">
          <div class="menu-title">
            <h3>Por favor precione CTRL + B para abrir este menu em qualquer lugar</h3>
            <input type="text" placeholder="Digite algo para a pesquisa"/>
          </div>
          <div class="menu-headlines">
            <router-link
              :to="link.href"
              class="headline-box"
              v-for="(link, i) in navs"
              :key="i"
              @click="downwards = true"
            >
              <div class="index">^{{i + 1}}</div>
              <i :class="'mdi mdi-' + link.icon"></i>
              <span>{{ link.title }}</span>
            </router-link>
          </div>
          <v-divider class="divider"></v-divider>
          <div class="your-links">
            <h6>Cadastros</h6>
            <ol>
              <router-link
                v-for="(subLink, i) in subLinks"
                :to="subLink.href"
                :key="i"
                @click="downwards = true"
              >
                <i :class="'mdi mdi-' + subLink.icon"></i>
                {{ subLink.title }}
              </router-link>
            </ol>
          </div>
        </div>
      </div>
    </div>
    <div class="col-4 d-flex align-items-center account-box">
      <div class="account-img-box">
        <i class="mdi mdi-account"></i>
      </div>
      <span>{{ user.attributes?.name }}</span>
      <div
        class="close-box"
        @click="SET_LOG_OUT"
        >
        <i class="mdi mdi-close"></i>
      </div>
    </div>
  </header>
</template>

<script>
import { mapMutations, mapState } from "vuex";
export default {
  name: "TheHeader",
  data: () => ({
    downwards: true,
    navs: [
      {
        title: "Pesquisa",
        href: "/Home",
        icon: "search"
      },
      {
        title: "Usuários",
        href: "/users",
        icon: "account"
      },
      {
        title: "Canais",
        href: "/market",
        icon: "cash"
      },
      {
        title: "Iniciativas",
        href: "/solutions",
        icon: "handshake-outline"
      },
      {
        title: "Perguntas",
        href: "/questions",
        icon: "help"
      },
      {
        title: "Respostas",
        href: "/answers",
        icon: "lightbulb"
      },
      {
        title: "Formulários",
        href: "/forms",
        icon: "list-box-outline"
      },
      {
        title: "Rel. Respostas",
        href: "/awnsers-report",
        icon: "account-arrow-right"
      },
      {
        title: "Rel. Inteligente",
        href: "/smart-report",
        icon: "account-badge"
      },
      {
        title: "Rel. Iniciativas",
        href: "/solutions-report",
        icon: "account-alert"
      }
    ],
    subLinks: [
      {
        title: "Cadastro de Perguntas",
        href: "/questions",
        icon: "help"
      },
      {
        title: "Cadastro de Respostas",
        href: "/answers",
        icon: "lightbulb"
      },
      {
        title: "Cadastro de Mercado",
        href: "/market",
        icon: "cash"
      }
    ]
  }),
  computed: {
    ...mapState("login", [
      "user"
    ])
  },
  methods: {
    ...mapMutations("login", [
      "SET_LOG_OUT"
    ])
  },
  mounted() {
    window.addEventListener("keydown", (e) => {
      if (e.ctrlKey && e.key === "b") {
        this.downwards = !this.downwards;
      }
    });
  }
};
</script>

<style scoped>
  .logo {
    box-shadow: none;
    font-size: 1.3rem;
    cursor: pointer;
  }
  .box-menu {
    position:absolute;
    width:40%;
    height: 600px;
    background-color:var(--primary-blue);
    transform: translateX(-09%);
    top: 0px;
    opacity: 0;
    transition: 0.3s ease-in-out;
    border-radius: 20px;
    pointer-events: none;
    overflow-y: scroll;
    z-index: 10;
  }
  .box-menu.active {
    top: 100px;
    opacity: 1;
    pointer-events: all;
  }
  .box-menu .arrow {
    position: absolute;
    top: -24px;
    transform: translateX(-8px);
    font-size: 1.8rem;
    color: var(--primary-blue);
  }
  .account-box {
    padding-left: 50px;
  }
  .account-box .account-img-box {
    width: 40px;
    height: 40px;
    background: var(--background-blue);
    border-radius: 50%;
    margin-right: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .account-box .account-img-box i {
    font-size: 1.5rem;
  }
  .account-box .close-box {
    width: 20px;
    height: 20px;
    background: var(--background-blue);
    border-radius: 50%;
    margin-left: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.2rem;
    cursor: pointer;
  }
  .menu-content {
    padding: 50px;
    color: white;
  }
  .menu-content .menu-title h3 {
    font-size: 1.2rem;
  }
  .menu-content .menu-title input {
    background: white;
    width: 100%;
    padding: 10px;
    border-radius: 10px;
    outline: none;
    margin: 30px 0px;
    text-align: center;
  }
  .menu-content .menu-headlines {
    display: flex;
    flex-flow: wrap;
    justify-content: center;
  }
  .menu-content .menu-headlines a {
    width: 200px;
    height: 200px;
    border-radius: 10px;
    background:var(--strong-blue);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: white;
    font-size: 1.5rem;
    text-decoration: none;
    transition: 0.2s ease-in-out;
    margin: 10px;
  }
  .menu-content .menu-headlines a:hover {
    background: var(--hover-blue);
  }
  .menu-content .menu-headlines a .index {
    position: relative;
    right: -35%;
    font-size: 0.8rem;
    opacity: 0.6;
    font-weight: 600;
    letter-spacing: 2px;
  }
  .menu-content .menu-headlines i {
    font-size: 5rem;
  }
  .menu-content .divider {
    background:white;
    padding: 1px;
    margin: 30px 0px;
  }
  .menu-content .your-links {
    text-align: start;
  }
  .menu-content .your-links h6 {
    font-weight: 600;
    font-size: 1.2rem;
  }
  .menu-content .your-links ol {
    list-style: none;
    padding: 0;
    display: flex;
    flex-direction: column;
  }
  .menu-content .your-links ol a {
    margin: 5px 0px;
    color: white;
    text-decoration: none;
  }
</style>