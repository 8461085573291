import axios from "axios";
import interceptors from "../router/interceptors";
import store from "../store";

const api = axios.create({
  baseURL: 'https://api.hf.sebraego.com.br/',
  timeout: 100000
});

api.interceptors.request.use((config) => interceptors(config));

api.interceptors.request.use(function(config) {
  store.dispatch("loader/actionSetLoader");
  return config;
});

api.interceptors.response.use(
  (response) => {
    store.dispatch("loader/actionSetLoader");
    return response;
  },
  (error) => {
    store.dispatch("loader/actionSetLoader");
    return error;
  }
);

export default api;
