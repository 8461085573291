import api from "@/services/api";
export default {
  async actionGetSolutions({ commit }, payload) {
    const solutions = await api.get("/v1/solutions", { params: payload });
    commit("SET_SOLUTIONS", solutions.data.data);
    commit("SET_CATEGORIES", solutions.data.data.map((e, i) => ({ title: e.category, id: i })));
    commit("SET_PAGINATION", solutions.data.meta);
  },
  async actionGetAllSolutions() {
    const solutions = await api.get("/v1/solutions", { params: { no_paginate: true } });
    return solutions.data.data.map((e, i) => ({ title: e.category, id: i }));
  },
  async actionGetSolutionEdit({ commit }, payload) {
    const solution = await api.get("/v1/solutions/" + payload);
    return solution.data;
  },
  async actionEditSolution({ commit }, payload) {
    const solutionsEdit = await api.put("/v1/solutions/" + payload.id, payload.solution);
    if (solutionsEdit.response) {
      Object.keys(solutionsEdit.response.data.errors).forEach(key => {
        this._vm.$toast.error(key + " " + solutionsEdit.response.data.errors[key][0]);
      });
      return false;
    } else {
      this._vm.$toast.success("Iniciativa editada com sucesso !");
      commit("UPDATE_SOLUTIONS", solutionsEdit.data);
      return true;
    }
  },
  async actionSaveSolution({ commit }, payload) {
    const createdSolution = await api.post("/v1/solutions", payload);
    if (createdSolution.response) {
      Object.keys(createdSolution.response.data.errors).forEach(key => {
        this._vm.$toast.error(key + " " + createdSolution.response.data.errors[key][0]);
      });
      return false;
    } else {
      this._vm.$toast.success("Iniciativa cadastrada com sucesso !");
      commit("SET_NEW_SOLUTION", createdSolution.data);
      return true;
    }
  },
  async actionDeleteSolution({ commit }, payload) {
    const deletedSolution = await api.delete("/v1/solutions/" + payload.id);
    if (deletedSolution.response) {
      Object.keys(deletedSolution.response.data.errors).forEach(key => {
        this._vm.$toast.error(key + " " + deletedSolution.response.data.errors[key][0]);
      });
      return false;
    } else {
      this._vm.$toast.success("Iniciativa excluída com sucesso !");
      commit("DELETE_SOLUTION", payload);
      return true;
    }
  }
};