export default {
  SET_SOLUTIONS(state, payload) {
    state.solutions = payload;
  },
  UPDATE_SOLUTIONS(state, payload) {
    const newSolutions = [];
    state.solutions.forEach(item => {
      if (item.id === payload.id) {
        item = payload;
      }
      newSolutions.push(item);
    });
    state.solutions = newSolutions;
  },
  SET_NEW_SOLUTION(state, payload) {
    state.solutions.unshift(payload);
  },
  DELETE_SOLUTION(state, payload) {
    const index = state.solutions.findIndex(e => e.id === payload.id);
    state.solutions.splice(index, 1);
  },
  SET_CATEGORIES(state, payload) {
    state.allCategories = payload;
  },
  SET_PAGINATION(state, payload) {
    state.pagination = payload;
  }
};