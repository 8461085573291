export default [
  {
    path: "/questions",
    component: () => import("@/views/QuestionsView"),
    children: [
      {
        name: "questions.list",
        path: "/",
        component: () => import("@/components/pages/questions/QuestionsList.vue"),
        meta: {
          title: "Cadastro de Perguntas"
        }
      },
      {
        name: "questions.form",
        path: "/questions/register",
        component: () => import("@/components/pages/questions/QuestionsForm.vue"),
        meta: {
          title: "Cadastro de Perguntas"
        }
      }
    ]
  }
];