export default [
  {
    path: "/awnsers-report",
    component: () => import("@/views/AwnserReport"),
    children: [
      {
        name: "awnsers.report",
        path: "/",
        component: () => import("@/components/pages/reports/awnsers-report/AwnserReport")
      }
    ]
  }
];