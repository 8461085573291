import router from "@/router";

export default {
  SET_USER(state, payload) {
    state.user = payload;
  },
  SET_LOG_OUT(state, _) {
    localStorage.removeItem("loggedUser");
    state.user = {};
    router.push("/login");
  }
};