<template>
  <v-app>
    <v-main>
      <the-loader v-if="loading"/>
      <the-header v-if="$route.name !== 'login.component'"/>
      <router-view/>
      <the-footer />
    </v-main>
  </v-app>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import TheHeader from "./components/layout/TheHeader";
import TheFooter from "./components/layout/TheFooter";
import TheLoader from "./components/layout/TheLoader.vue";
export default {
  components: {
    TheHeader,
    TheFooter,
    TheLoader
  },
  name: "App",
  data: () => ({
    //
  }),
  computed: {
    ...mapState("login", [
      "user"
    ]),
    ...mapState("loader", [
      "loading"
    ])
  },
  methods: {
    ...mapMutations("login", [
      "SET_USER"
    ])
  },
  async mounted() {
    const loggedUser = JSON.parse(localStorage.getItem("loggedUser"));
    if (loggedUser?.attributes) {
      this.SET_USER(loggedUser);
    } else {
      this.$router.push("/login");
    }
  }
};
</script>

<style>
@import url("./assets/css/sidebar.css");
:root {
  --primary-blue:#256CC1;
  --strong-blue:#0359cb;
  --background-blue:#f8fbff;
  --hover-blue:#0a3f85;
  --table-background:#b8cfed;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

a.router-link-active {
  background: var(--hover-blue) !important;
}
.background {
  min-width: 100vw;
  min-height: 100vh;
}
.v-label--active {
  transform: translateY(-40px) scale(0.75) !important;
}
</style>