export default [
  {
    path: "/solutions-report",
    component: () => import("@/views/SolutionsReport"),
    children: [
      {
        name: "solutions.report",
        path: "/",
        component: () => import("@/components/pages/reports/solutions-report/SolutionsReport")
      }
    ]
  }
];