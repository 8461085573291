export default [
  {
    path: "/home",
    component: () => import("@/views/HomeView"),
    children: [
      {
        name: "home.component",
        path: "/home"
        /* component: () => import("@/components/pages/home/HomeComponent") */
      }
    ]
  }
];