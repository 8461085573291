<template>
  <div class="background-loader">
    <div class="loader"></div>
  </div>
</template>

<script>
export default {
  name: "TheLoader"
};
</script>

<style scoped>
  .background-loader {
    width: 100vw;
    min-height: 100%;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    backdrop-filter: contrast(0.5);
    z-index: 999;
  }
  .loader {
  border: 16px solid #f3f3f3;
  border-top: 16px solid var(--primary-blue);
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>