export default [
  {
    path: "/forms",
    component: () => import("@/views/FormsList.vue"),
    children: [
      {
        name: "forms.list",
        path: "/",
        component: () => import("@/components/pages/forms/FormsList.vue"),
        meta: {
          title: "Cadastro de Formulários"
        }
      }
    ]
  }
];