export default [
  {
    path: "/login",
    component: () => import("@/views/LoginView"),
    children: [
      {
        name: "login.component",
        path: "/login",
        component: () => import("@/components/pages/login/LoginComponent")
      }
    ]
  }
];