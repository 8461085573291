import api from "@/services/api";

export default {
  async actionGetUsers({ commit }, payload) {
    const users = await api.get("/v1/users", { params: payload });
    commit("SET_USERS", users.data.data);
    commit("SET_PAGINATION", users.data.meta);
  },
  async actionSaveUser({ commit }, payload) {
    const user = await api.post("/v1/users", { user: payload });
    if (user.response) {
      Object.keys(user.response.data.errors).forEach(key => {
        this._vm.$toast.error(key + " " + user.response.data.errors[key][0]);
      });
    } else {
      this._vm.$toast.success("Usuário cadastrado com sucesso !");
      commit("SET_NEW_USER", user.data);
    }
  },
  async actionEditUser({ commit }, payload) {
    const user = await api.put(`/v1/users/${payload.id}`, { user: payload });
    if (user.response) {
      Object.keys(user.response.data.errors).forEach(key => {
        this._vm.$toast.error(key + " " + user.response.data.errors[key][0]);
      });
    } else {
      this._vm.$toast.success("Usuário editado com sucesso !");
      commit("SET_EDIT_USER", user.data);
    }
  },
  async actionDeleteUser({ commit }, payload) {
    const user = await api.delete(`/v1/users/${payload.id}`);
    if (user.response) {
      Object.keys(user.response.data.errors).forEach(key => {
        this._vm.$toast.error(key + " " + user.response.data.errors[key][0]);
      });
    } else {
      this._vm.$toast.success("Usuário deletado com sucesso !");
      commit("SET_DELETE_USER", payload);
    }
  },
  async actionGetPartners({ commit }) {
    commit("SET_PARTNERS", []);
  }
};