import api from "@/services/api";
export default {
  async actionGetForms({ commit }, payload) {
    const forms = await api.get("/v1/forms/list", payload);
    commit("SET_FORMS", forms.data.data);
  },
  async actionSaveForm(_, payload) {
    await api.post("/v1/forms", payload);
  },
  async actionDeleteForm(_, payload) {
    await api.delete("/v1/forms/" + payload);
  }
};