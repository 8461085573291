export default {
  SET_USERS(state, payload) {
    state.users = payload;
  },
  SET_PAGINATION(state, payload) {
    state.pagination = payload;
  },
  SET_NEW_USER(state, payload) {
    state.users.unshift(payload);
  },
  SET_EDIT_USER(state, payload) {
    const index = state.users.findIndex(e => e.id === payload.id);
    state.users[index] = Object.assign(state.users[index], payload);
  },
  SET_DELETE_USER(state, payload) {
    const index = state.users.findIndex(e => e.id === payload.id);
    state.users.splice(index, 1);
  },
  SET_PARTNERS(state, payload) {
    state.partners = payload;
  }
};