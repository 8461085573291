<template>
  <footer class="col-12">
    <div class="col-10 footer">
      Sebrae: {{ info.version }}
    </div>
  </footer>
</template>

<script>
import packageInfo from "../../../package.json";
export default {
  name: "TheFooter",
  setup: () => ({
    info: packageInfo
  })
};
</script>

<style scoped>
  .footer {
    position: absolute;
    bottom: -100px;
    display: flex;
    justify-content: flex-end;
    height: 100px;
    align-items:center;
  }
</style>