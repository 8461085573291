import api from "@/services/api";
export default {
  async actionLogin({ commit }, payload) {
    const login = new FormData();
    login.append("username", payload.username);
    login.append("password", payload.password);
    const user = await api.post("/sign_in", login);
    localStorage.setItem("loggedUser", JSON.stringify(user.data.data));
    commit("SET_USER", user.data.data);
  }
};