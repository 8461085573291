export default [
  {
    path: "/market",
    component: () => import("@/views/MarketView"),
    children: [
      {
        name: "market.list",
        path: "/",
        component: () => import("@/components/pages/market/MarketList.vue"),
        meta: {
          title: "Cadastro de Canais"
        }
      }
    ]
  }
];